// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Partners_wrapper__bLCQM {
  margin-bottom: 90px;
}
.Partners_wrapper__bLCQM .Partners_title__0mbvw {
  margin-bottom: 50px;
}
.Partners_wrapper__bLCQM .Partners_list__MDsUR {
  display: flex;
  gap: 30px;
}
.Partners_wrapper__bLCQM .Partners_list__MDsUR .Partners_item__rZWZx {
  border: 1px solid #454545;
  border-radius: 16px;
  padding: 24px 33px;
}
.Partners_wrapper__bLCQM .Partners_list__MDsUR .Partners_item__rZWZx .Partners_imgNormal__Y-BBR {
  display: block;
}
.Partners_wrapper__bLCQM .Partners_list__MDsUR .Partners_item__rZWZx .Partners_imgHover__3bIDq {
  display: none;
}
.Partners_wrapper__bLCQM .Partners_list__MDsUR .Partners_item__rZWZx:hover .Partners_imgNormal__Y-BBR {
  display: none;
}
.Partners_wrapper__bLCQM .Partners_list__MDsUR .Partners_item__rZWZx:hover .Partners_imgHover__3bIDq {
  display: block;
}
@media screen and (max-width: 630px) {
  .Partners_wrapper__bLCQM .Partners_list__MDsUR .Partners_item__rZWZx {
    padding: 10px 17px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Partners/Partners.module.scss"],"names":[],"mappings":"AAAA;EAEE,mBAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,aAAA;EACA,SAAA;AADJ;AAKI;EACE,yBAAA;EACA,mBAAA;EACA,kBAAA;AAHN;AAKM;EACE,cAAA;AAHR;AAMM;EACE,aAAA;AAJR;AAOM;EACE,aAAA;AALR;AAQM;EACE,cAAA;AANR;AAkBA;EAGM;IACE,kBAAA;EAlBN;AACF","sourcesContent":[".wrapper {\n  // border: 1px solid black;\n  margin-bottom: 90px;\n\n  .title {\n    margin-bottom: 50px;\n  }\n\n  .list {\n    display: flex;\n    gap: 30px;\n    // width: 217px;\n    // height: 104px;\n\n    .item {\n      border: 1px solid #454545;\n      border-radius: 16px;\n      padding: 24px 33px;\n\n      .imgNormal {\n        display: block;\n      }\n\n      .imgHover {\n        display: none;\n      }\n\n      &:hover .imgNormal {\n        display: none;\n      }\n\n      &:hover .imgHover {\n        display: block;\n      }\n    }\n  }\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n\n@media screen and (max-width: 630px) {\n  .wrapper {\n    .list {\n      .item {\n        padding: 10px 17px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Partners_wrapper__bLCQM`,
	"title": `Partners_title__0mbvw`,
	"list": `Partners_list__MDsUR`,
	"item": `Partners_item__rZWZx`,
	"imgNormal": `Partners_imgNormal__Y-BBR`,
	"imgHover": `Partners_imgHover__3bIDq`
};
export default ___CSS_LOADER_EXPORT___;
