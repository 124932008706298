import React, { useState } from "react";
import css from "./Slider.module.scss";
import { ReactComponent as ArrowLeft } from "../../../media/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../../media/icons/arrowRight.svg";
import { ReactComponent as CubePagination } from "../../../media/icons/cubePagination.svg";
import { ReactComponent as PaginationCubeActive } from "../../../media/icons/paginationCubeActive.svg";
import { ReactComponent as PaginationBorder } from "../../../media/icons/paginationBorder.svg";

const Slider = ({ slides }) => {

  const slidesPerPage = 5;
  const totalSlides = slides.length;
  const initialIndex = Math.floor((totalSlides - slidesPerPage) / 2);

  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const handlePrev = () => {
    if (currentIndex === 0) {
      return;
    }
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    if (currentIndex + slidesPerPage === totalSlides - 1) {
      return;
    }

    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const itemWidth = 10;
  const marginItem = 5.95; // компенсаціє маргінів активного елемента
  const paginationItemWidth = itemWidth + marginItem;

  return (
      <div className={css["slider"]}>
        {/* кнопки */}
        <ArrowLeft className={css["prevArrow"]} onClick={handlePrev} />
        <ArrowRight className={css["nextArrow"]} onClick={handleNext} />

        {/* слайдер карусель */}
        <div className={css["slidesContainer"]}>
          <div className={css["wrapperContPag"]}>
            <ul
              className={css["slidesInnerContainer"]}
              style={{
                transform: `translateX(-${
                  currentIndex * (100 / slidesPerPage)
                }%)`,
              }}
            >
              {slides.map((item, index) => (
                <li key={index} className={`${css["slide"]}`}>
                  <a
                    href={item.linkURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={item["imageURL"]} alt={item.title} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* пагінація */}
        <div className={css["pagination"]}>
          {/* рамка */}
          <PaginationBorder className={css["pagination_border"]} />

          <div
            className={css["pagination_dots"]}
            style={{
              // transform: `translateX(-${currentIndex * (100 / totalSlides)}%)`,
              transform: `translateX(-${ paginationItemWidth * currentIndex}px)`,
            }}
          >
            {Array.from({ length: totalSlides }).map((_, index) => (
              <div
                key={index}
                className={`${css["dot"]} ${
                  index === currentIndex ? css["activeDot"] : ""
                }`}
                onClick={() => setCurrentIndex(index)}
              >
                {index === currentIndex ? (
                  <PaginationCubeActive />
                ) : (
                  <CubePagination />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default Slider;
