import React from "react";
import css from "./Partners.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from "react-i18next";
import Slider from "./Slider/Slider";
import { partners } from "./Slider/dataSlider";
// import { Link } from "react-router-dom";
// import ProductCard from "../ProductCard";

const Partners = ({ data }) => {
  // console.log(data);
  const { t } = useTranslation();
  // const partners = t(`${data}.partners`, { returnObjects: true });

  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.title}>
          <Title title={t(`${data}.title`)} />
        </div>

        {/* <ul className={css.list}>
          {partners.map((el, i) => (
              <Link to={el.link} target="_blank" key={i}>
                <li className={css.item} key={i}>
                <img className={css.imgNormal} width={'100%'} height={56} src={el.imgUrl} alt={el.title} />
                <img
                  width={'100%'}
                  height={56} 
                  className={css.imgHover}
                  src={el.imgUrlColor}
                  alt={el.title}
                />
                </li>
              </Link>
          ))}
        </ul> */}
        <Slider slides={partners} />
      </div>
    </Container>
  );
};

export default Partners;
