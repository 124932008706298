export const partners = [
 
    {
        title: 'IT Maksimum',
        imageURL: '/media/images/partners/maximum.png',
        linkURL: 'https://itmaximum.com.ua/',
    },
    {
        title: 'ClEANING сервіс',
        imageURL: '/media/images/partners/clean.png',
        linkURL: 'https://www.instagram.com/cleaning.service.chg?igsh=YzYxZmsycTJtemQ5',
    },
    {
        title: 'Aгрологістика',
        imageURL: '/media/images/partners/agrologistik.svg',
        linkURL: 'https://agrologistics.com.ua/',
    },
    {
        title: 'Західний буг',
        imageURL: '/media/images/partners/westBug.svg',
        linkURL: 'https://zahbug.com/',
    },
    {
        title: 'Milkriver',
        imageURL: '/media/images/partners/milkriver.png',
        linkURL: '#',
    },
    {
        title: 'Citadel',
        imageURL: '/media/images/partners/citadel.png',
        linkURL: 'https://citadel-llc.com.ua/ua/',
    },
    {
        title: 'Галицька правнича група',
        imageURL: '/media/images/partners/galych.png',
        linkURL: 'https://galpravgroup.com.ua/',
    },
    {
        title: 'IT Maksimum',
        imageURL: '/media/images/partners/maximum.png',
        linkURL: 'https://itmaximum.com.ua/',
    },
    {
        title: 'ClEANING сервіс',
        imageURL: '/media/images/partners/clean.png',
        linkURL: 'https://www.instagram.com/cleaning.service.chg?igsh=YzYxZmsycTJtemQ5',
    },
    {
        title: 'Aгрологістика',
        imageURL: '/media/images/partners/agrologistik.svg',
        linkURL: 'https://agrologistics.com.ua/',
    },
    {
        title: 'Західний буг',
        imageURL: '/media/images/partners/westBug.svg',
        linkURL: 'https://zahbug.com/',
    },
    {
        title: 'Milkriver',
        imageURL: '/media/images/partners/milkriver.png',
        linkURL: '#',
    },
    {
        title: 'Citadel',
        imageURL: '/media/images/partners/citadel.png',
        linkURL: 'https://citadel-llc.com.ua/ua/',
    },
    {
        title: 'Галицька правнича група',
        imageURL: '/media/images/partners/galych.png',
        linkURL: 'https://galpravgroup.com.ua/',
    },
    {
        title: 'IT Maksimum',
        imageURL: '/media/images/partners/maximum.png',
        linkURL: 'https://itmaximum.com.ua/',
    },
    {
        title: 'ClEANING сервіс',
        imageURL: '/media/images/partners/clean.png',
        linkURL: 'https://www.instagram.com/cleaning.service.chg?igsh=YzYxZmsycTJtemQ5',
    },
    {
        title: 'Aгрологістика',
        imageURL: '/media/images/partners/agrologistik.svg',
        linkURL: 'https://agrologistics.com.ua/',
    },
    {
        title: 'Західний буг',
        imageURL: '/media/images/partners/westBug.svg',
        linkURL: 'https://zahbug.com/',
    },
    {
        title: 'Milkriver',
        imageURL: '/media/images/partners/milkriver.png',
        linkURL: '#',
    },
    {
        title: 'Citadel',
        imageURL: '/media/images/partners/citadel.png',
        linkURL: 'https://citadel-llc.com.ua/ua/',
    },
    {
        title: 'Галицька правнича група',
        imageURL: '/media/images/partners/galych.png',
        linkURL: 'https://galpravgroup.com.ua/',
    },
    {
        title: 'IT Maksimum',
        imageURL: '/media/images/partners/maximum.png',
        linkURL: 'https://itmaximum.com.ua/',
    },
    {
        title: 'ClEANING сервіс',
        imageURL: '/media/images/partners/clean.png',
        linkURL: 'https://www.instagram.com/cleaning.service.chg?igsh=YzYxZmsycTJtemQ5',
    },
];